<template>
    <v-card style="min-height: 100%">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4">
            <v-card elevation="2">

                  <v-card-text>
                    <div class="text--primary" v-t="'pricaCatalogTitle'"></div>
                    <div class="text--primary mt-3" v-t="'pricaCatalogSubtitle'"></div>
                  </v-card-text>

                  <v-card-actions>
                    <!-- <v-btn color="orange lighten-2" text>Add</v-btn> -->
                    <v-spacer></v-spacer>
                    <v-btn icon @click="show = !show">
                      <v-icon>{{ !show ? 'mdi-plus' : 'mdi-minus' }}</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-expand-transition>
                    <div v-show="show">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="8">
                            <v-text-field 
                              v-model="newPriceList.code" 
                              outlined 
                              clearable 
                              dense 
                              hide-details="auto" 
                              autocomplete="nope" 
                              :label="$t('code')"
                            ></v-text-field>
                          </v-col>        
                          <v-col cols="12" sm="4">
                            <v-select 
                              v-model="newPriceList.currencyId" 
                              dense 
                              outlined 
                              hide-details="auto" 
                              autocomplete="nope" 
                              :items="dropdownCurrencies" 
                              :label="$t('currency')"
                            ></v-select>
                          </v-col>        
                          <v-col cols="12" sm="12">
                            <v-text-field 
                              v-model="newPriceList.description" 
                              outlined 
                              clearable 
                              dense 
                              hide-details="auto" 
                              autocomplete="nope" 
                              :label="$t('description')"
                            ></v-text-field>
                          </v-col>        
                          <v-col cols="12" sm="12" v-if="newPriceList.currencyId != 3">
                            <v-text-field 
                              v-model="newPriceList.contractualConversionRateFromAccountingCurrency" 
                              outlined 
                              clearable 
                              dense 
                              type="number"
                              hide-details="auto" 
                              autocomplete="nope" 
                              :label="$t('contractualConversionRate')"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="submitPriceCatalog()" text v-t="'add'"></v-btn>
                      </v-card-actions>
                    </div>
                  </v-expand-transition>
            </v-card>
          </v-col>
          <v-col cols="12" sm="8">
            <v-card elevation="2">
              <v-card-text>
                <v-row style="margin-bottom: 50px">
                  <v-col cols="12" sm="12">
                    <v-alert color="blue" outlined text class="center" @click="downloadCsv()">
                      <v-icon class="mr-2" color="success">mdi-cloud-download</v-icon>
                      {{ $t('downloadExample') }}
                    </v-alert>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-select v-model="model.priceCatalogId" outlined clearable hide-details="auto" autocomplete="nope" :items="priceCatalogDropdown" :label="$t('priceCatalog')"></v-select>
                  </v-col>
                  <v-col cols="12" sm="1" v-if="!!model.priceCatalogId">
                    <v-alert color="blue" outlined text class="center">
                      <v-icon color="success">mdi-arrow-right</v-icon>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" sm="7" v-if="!!model.priceCatalogId">
                    <v-file-input outlined v-model="model.file" :label="$t('fileInput')" filled @change="readContent"></v-file-input>
                  </v-col>
                </v-row>
                <v-row style="margin-bottom: 50px" v-if="!!model.priceCatalogId && !!model.content">
                  <v-col cols="12" sm="12" v-if="!!getWarningDescription()">
                    <v-alert color="red" outlined prominent text type="warning">
                      <p>{{ getWarningDescription().title }}</p>
                      <p>{{ getWarningDescription().body }}</p>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-alert color="blue" outlined prominent text type="info">
                      <v-row align="center">
                        <v-col class="grow">{{ getItemsPriceResume() }}</v-col>
                        <v-col class="shrink">
                          <v-btn color="primary" depressed elevation="2" large raised @click="loadElements" v-t="'upload'"></v-btn>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import { sync, get, call } from "vuex-pathify";
  
  export default {
    created() { 
      this.initialize();
    },
    computed: {
      priceCatalogDropdown: get("priceCatalogs/dropdownPriceCatalogs"),
      items: get("items/items"),
      dropdownCurrencies: get("currencies/dropdownCurrencies"),
    },
    data: () => ({
      show: false,
      newPriceList : {
        code : "",
        description : "",
        currencyId : 3,
        active : true,
        contractualConversionRateFromAccountingCurrency : 1,
        tenantUuid : localStorage.getItem('tenantUuid')
      },  
      model : { 
        priceCatalogId : 0,
        content: null 
      }
    }),
    methods: {
      handleInput(el) {
        // console.info(el);
      },  
      initialize() {
        this.initItemCollection();
        this.initPriceCatalogCollection();
        this.initCurrenciesCollection();
      },
      submitPriceCatalog: function() {
        if(!this.newPriceList.code || !this.newPriceList.description) return;
        this.priceCatalogPost(this.newPriceList)
        .finally(() => {
          this.show = !this.show;
          this.newPriceList = {code : "", description : "", currencyId : 3, active : true, contractualConversionRateFromAccountingCurrency : 1, tenantUuid : localStorage.getItem('tenantUuid')};
          this.initialize()
        })
      },
      readContent : function(data) {
        if (!data) return;
        var reader = new FileReader();
        reader.readAsText(data);
        reader.onload = () => {
          this.model.content = [...reader.result
          .split(/\r?\n|\r/)
          .filter(el => !!el)
          .map(el => {
            let code = el.split(';')[0].toUpperCase().trim().replaceAll("'", '');
            let value = el.split(';')[1];
            let item = this.items.find(el => el.code == code || el.barcode == code);
            return ({ itemId: item?.id, code, price: +value });
          })
          // .filter(el => !!el.itemId)
          ];
        }
      },
      getItemsPriceResume : function () {
        let minPrice = this.model.content.reduce((a, b) => { 
          if(a > b.price) a = b.price; 
          return a; 
        }, Infinity);
        let maxPrice = this.model.content.reduce((a, b) => { 
          if(a < b.price) a = b.price; 
          return a; 
        }, -Infinity);
        // let avgPrice = this.model.content.reduce((avg, b, _, { length }) => {
        //   return avg + b.price / length;
        // }, 0)
        return this.$t('itemsPriceResume', {q:this.model.content.length, min:minPrice, max: maxPrice}); //', Avg: ${avgPrice}'
      },
      getWarningDescription : function() {
        return this.model.content.filter(el => !!el.itemId).length == this.model.content.length
         ? null
         : { 
          title: this.$t('loadedValidItems') + `: ${this.model.content.filter(el => !!el.itemId).length}.`, 
          body: this.$t('cannotRecognize') + ` : ${this.model.content.filter(el => !el.itemId).map(x => x.code).join(', ')}`
        };
      },
      loadElements : function () {
        this.apiPriceCatalogIdPatchFromListPatch({...this.model, itemsPrices: this.model.content.reduce((a, b) => {
          a[b.itemId] = b.price;
          return a;
        }, {})})
        .then(() => {
          this.model.content = null;
          this.model.priceCatalogId = null;
        });
      },
      downloadCsv: function() {
        var binaryData = [];
        var data = ['MFB01','MFB02','MFB03','MFB04','MFB05','MFB06','MFB07','MFB08','MFB09'];
        binaryData.push(data.map((el, i) => `${el};${i+1}.0${i+1}\n`).join(''));
        const url = window.URL.createObjectURL(new Blob(binaryData));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `example.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      },
      ...call("items/*"),
      ...call("priceCatalogs/*"),
      ...call("currencies/*"),
    },
    mounted: function () {
        this._keyListener = function(e) {
            if ((e.key === 'q' && e.ctrlKey) || (e.metaKey && e.key === 'b')) {
              e.preventDefault();
            }
        };
        document.addEventListener('keydown', this._keyListener.bind(this));
    },
    beforeDestroy() { 
        document.removeEventListener('keydown', this._keyListener); 
    },
  };
  </script>